main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 20px 20px;
  @media (min-width: 768px) {
    margin: 0 75px 50px 75px;
  }
  @media (min-width: 1024px) {
    margin: 0 100px 50px 100px;
  }
}

.logements {
  margin-top: 22px;
  display: flex;
  width: 100%;
  gap: 20px;
  flex-direction: column;
  &__card {
    &__article {
      position: relative;
      display: flex;
      flex-direction: column;
      &__img {
        border-radius: 10px;
        height: 225px;
        object-fit: cover;
      }
      &__h2 {
        position: absolute;
        bottom: 15px;
        left: 15px;
        margin: 0;
        color: #fff;
      }
    }
  }
  @media (min-width: 768px) {
    border-radius: 25px;
    margin-top: 22px;
    display: grid;
    grid-template-rows: 250px 250px 250px;
    grid-template-columns: 32.5% 32.5% 32.5%;
    width: 100%;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    &__card {
      &__article {
        &__img {
          height: 250px;
          width: 100%;
        }
        // &__h2 {
        // }
      }
    }
    background-color: $background_grey;
    padding: 50px;
  }
  @media (min-width: 1024px) {
    grid-template-rows: 340px 340px 340px;
    grid-template-columns: 27.5% 27.5% 27.5%;
    gap: 50px 60px;
    &__card {
      &__article {
        &__img {
          height: 340px;
          // height: 100%;
          width: 100%;
        }
        // &__h2 {
        // }
      }
    }
  }
}
