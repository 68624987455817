.banner {
  position: relative;

  height: 111px;
  width: 100%;
  //   margin-top: 50px;
  border-radius: 10px;

  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-color: #000;
    opacity: 0.6;
  }
  &--opacity-about {
    &::before {
      opacity: 0.1;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0;
    padding: 16px;
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    flex-direction: column;
  }
  @media (min-width: 768px) {
    height: 223px;
  }
  // @media (min-width: 1024px) {

  // }
}
