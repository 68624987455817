.collapse {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__arrow {
    padding: 10px;
  }
  &__button {
    cursor: pointer;
    height: fit-content;
    display: flex;
    flex-direction: column;
    color: white;
    border: none;
    border-radius: 10px 10px 12px 12px;
    background-color: $rouge-kasa;
    width: 100%;

    &__container-title {
      height: 30px;
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      &__h3 {
        padding: 10px;
      }
    }
  }
  &__text-container {
    width: 100%;
    &__content {
      font-size: 12px;
      border-radius: 0 0 10px 10px;
      background-color: $background_grey;
      overflow: hidden;
      color: black;
      text-align: left;
      list-style-type: none;
      max-height: 0; /* Initialement replié */
      transition: max-height 0.3s ease-in-out;
      transition: padding 0.3s ease-in-out;
    }
    &__content-animation {
      max-height: 800px;
      padding: 20px;
    }
    &__equipments-item {
      width: 100%;
      list-style: none;
    }
  }
  &__arrow-down {
    transform: rotate(180deg);
  }
}
