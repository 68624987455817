.Header {
  margin: 20px;
  display: flex;
  justify-content: space-between;
  img {
    width: 130px;
    @media (min-width: 375px) {
      width: 145px;
    }
  }
  nav {
    display: flex;
    align-items: center;
    ul {
      gap: 20px;
      display: flex;
      li {
        font-size: 11px;
        list-style: none;
        @media (min-width: 375px) {
          font-size: 12px;
        }
      }
    }
  }
  @media (min-width: 768px) {
    margin: 50px 50px 50px 50px;
  }
  @media (min-width: 1024px) {
    margin: 50px 100px 50px 100px;
  }
}
