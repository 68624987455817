@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  font-family: montserrat;
}

.underline {
  border-bottom: solid 1px;
}

.banner {
  position: relative;
  height: 111px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.banner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-color: #000;
  opacity: 0.6;
}
.banner--opacity-about::before {
  opacity: 0.1;
}
.banner__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner__title {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  padding: 16px;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  flex-direction: column;
}
@media (min-width: 768px) {
  .banner {
    height: 223px;
  }
}

.collapse {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.collapse__arrow {
  padding: 10px;
}
.collapse__button {
  cursor: pointer;
  height: fit-content;
  display: flex;
  flex-direction: column;
  color: white;
  border: none;
  border-radius: 10px 10px 12px 12px;
  background-color: #ff6060;
  width: 100%;
}
.collapse__button__container-title {
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.collapse__button__container-title__h3 {
  padding: 10px;
}
.collapse__text-container {
  width: 100%;
}
.collapse__text-container__content {
  font-size: 12px;
  border-radius: 0 0 10px 10px;
  background-color: #f6f6f6;
  overflow: hidden;
  color: black;
  text-align: left;
  list-style-type: none;
  max-height: 0; /* Initialement replié */
  transition: max-height 0.3s ease-in-out;
  transition: padding 0.3s ease-in-out;
}
.collapse__text-container__content-animation {
  max-height: 800px;
  padding: 20px;
}
.collapse__text-container__equipments-item {
  width: 100%;
  list-style: none;
}
.collapse__arrow-down {
  transform: rotate(180deg);
}

.Header {
  margin: 20px;
  display: flex;
  justify-content: space-between;
}
.Header img {
  width: 130px;
}
@media (min-width: 375px) {
  .Header img {
    width: 145px;
  }
}
.Header nav {
  display: flex;
  align-items: center;
}
.Header nav ul {
  gap: 20px;
  display: flex;
}
.Header nav ul li {
  font-size: 11px;
  list-style: none;
}
@media (min-width: 375px) {
  .Header nav ul li {
    font-size: 12px;
  }
}
@media (min-width: 768px) {
  .Header {
    margin: 50px 50px 50px 50px;
  }
}
@media (min-width: 1024px) {
  .Header {
    margin: 50px 100px 50px 100px;
  }
}

footer {
  display: flex;
  flex-direction: column;
  background-color: black;
  height: 209px;
  color: white;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
footer img {
  height: 40px;
  width: 122px;
}
footer p {
  width: 133px;
  text-align: center;
}
@media (min-width: 768px) {
  footer p {
    width: unset;
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 20px 20px;
}
@media (min-width: 768px) {
  main {
    margin: 0 75px 50px 75px;
  }
}
@media (min-width: 1024px) {
  main {
    margin: 0 100px 50px 100px;
  }
}

.logements {
  margin-top: 22px;
  display: flex;
  width: 100%;
  gap: 20px;
  flex-direction: column;
}
.logements__card__article {
  position: relative;
  display: flex;
  flex-direction: column;
}
.logements__card__article__img {
  border-radius: 10px;
  height: 225px;
  object-fit: cover;
}
.logements__card__article__h2 {
  position: absolute;
  bottom: 15px;
  left: 15px;
  margin: 0;
  color: #fff;
}
@media (min-width: 768px) {
  .logements {
    border-radius: 25px;
    margin-top: 22px;
    display: grid;
    grid-template-rows: 250px 250px 250px;
    grid-template-columns: 32.5% 32.5% 32.5%;
    width: 100%;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    background-color: #f6f6f6;
    padding: 50px;
  }
  .logements__card__article__img {
    height: 250px;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .logements {
    grid-template-rows: 340px 340px 340px;
    grid-template-columns: 27.5% 27.5% 27.5%;
    gap: 50px 60px;
  }
  .logements__card__article__img {
    height: 340px;
    width: 100%;
  }
}

.page-appartement {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.appartement {
  display: flex;
  flex-direction: column;
  margin: 27px 20px 27px 20px;
}
.appartement__slider {
  position: relative;
  width: 100%;
}
.appartement__slider__image {
  border-radius: 10px;
  width: 100%;
  height: 255px;
  object-fit: cover;
}
.appartement__slider__arrow {
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.appartement__slider__arrow-left {
  position: absolute;
  top: 100px;
  left: 30px;
}
.appartement__slider__arrow-left__left {
  height: 20px;
}
.appartement__slider__arrow-right {
  position: absolute;
  top: 100px;
  right: 30px;
}
.appartement__slider__arrow-right__right {
  height: 20px;
  transform: rotate(180deg);
}
.appartement__content-container {
  width: 100%;
}
.appartement__content-container__content__text__title {
  color: #ff6060;
  font-weight: 500;
  font-size: 18px;
}
.appartement__content-container__content__text__location {
  font-weight: 500;
  font-size: 14px;
  margin: 5px 0 10px 0;
}
.appartement__content-container__content__text__tags-list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  list-style-type: none;
}
.appartement__content-container__content__text__tags-list__tag-item {
  width: fit-content;
  padding: 5px;
  font-size: 10px;
  color: white;
  font-weight: bold;
  background-color: #ff6060;
  border-radius: 5px;
}
@media (min-width: 768px) {
  .appartement {
    margin: 0px 50px 75px 50px;
  }
  .appartement__content-container {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  .appartement__content-container__content {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 1024px) {
  .appartement {
    margin: 0 100px 75px 100px;
  }
}

.profil {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profil__rate {
  display: flex;
  gap: 5px;
}
.profil__content {
  margin: 16px 0 12px 0;
  gap: 10px;
  display: flex;
  align-items: center;
}
.profil__content__name {
  font-weight: 500;
  font-size: 12px;
  color: #ff6060;
}
.profil__content__img {
  border-radius: 90px;
  height: 32px;
}
@media (min-width: 768px) {
  .profil {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (min-width: 768px) {
  .collapse--appartement {
    display: flex;
    flex-direction: row;
  }
}

.page-about {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .page-about {
    min-height: 100vh;
  }
}

.main-about {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (min-width: 768px) {
  .main-about {
    gap: 38px;
  }
}
@media (min-width: 768px) {
  .collapse--about {
    gap: 30px;
    margin: 0 50px;
  }
}
@media (min-width: 1024px) {
  .collapse--about {
    margin: 0 100px;
  }
}

.Page404 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.Page404__img {
  height: 99px;
  width: 198px;
  margin: 113px 0 113px 0;
}
.Page404__p {
  display: flex;
  flex-direction: column;
  color: #ff6060;
  text-align: center;
}
.Page404__link {
  margin: 113px 0 113px 0;
  text-decoration: underline;
}
@media (min-width: 1024px) {
  .Page404__img {
    height: 263px;
    width: 597px;
  }
  .Page404__p {
    flex-direction: row;
  }
}

