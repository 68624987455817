.page-appartement {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.appartement {
  display: flex;
  flex-direction: column;
  margin: 27px 20px 27px 20px;

  &__slider {
    position: relative;
    width: 100%;
    &__image {
      border-radius: 10px;
      width: 100%;
      height: 255px;
      object-fit: cover;
    }
    &__arrow {
      cursor: pointer;
      background-color: transparent;
      border: none;
    }
    &__arrow-left {
      position: absolute;
      top: 100px;
      left: 30px;
      &__left {
        height: 20px;
      }
    }
    &__arrow-right {
      position: absolute;
      top: 100px;
      right: 30px;
      &__right {
        height: 20px;
        transform: rotate(180deg);
      }
    }
  }

  &__content-container {
    width: 100%;
    &__content {
      &__text {
        &__title {
          color: $rouge-kasa;
          font-weight: 500;
          font-size: 18px;
        }
        &__location {
          font-weight: 500;
          font-size: 14px;
          margin: 5px 0 10px 0;
        }
        &__tags-list {
          display: flex;
          flex-direction: row;
          gap: 10px;
          list-style-type: none;
          &__tag-item {
            width: fit-content;
            padding: 5px;
            font-size: 10px;
            color: white;
            font-weight: bold;
            background-color: #ff6060;
            border-radius: 5px;
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    &__content-container {
      display: flex;
      gap: 20px;
      flex-direction: column;
      &__content {
        display: flex;
        justify-content: space-between;
        // &__text {
        // }
      }
    }
    margin: 0px 50px 75px 50px;
  }
  @media (min-width: 1024px) {
    margin: 0 100px 75px 100px;
  }
}

.profil {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__rate {
    display: flex;
    gap: 5px;
  }
  &__content {
    margin: 16px 0 12px 0;
    gap: 10px;
    display: flex;
    align-items: center;
    &__name {
      font-weight: 500;
      font-size: 12px;
      color: $rouge-kasa;
    }
    &__img {
      border-radius: 90px;
      height: 32px;
    }
  }
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
}
.collapse {
  @media (min-width: 768px) {
    &--appartement {
      display: flex;
      flex-direction: row;
    }
  }
}
